<template>
  <div v-if="loaded" class="email-service-info-slide-container">
    <client-setup />
    <settings :domain="domainName" />

    <div class="mt-3 mb-4 button-container">
      <secondary-button
        class="mr-2"
        button-text="Test Email"
        :loading="sendingTestEmail"
        aria-label="test email button"
        @onclick="sendTestDomainEmail()"
      />
      <primary-button
        button-text="Continue"
        aria-label="continue button"
        @onclick="$emit('next-slide')"
      />
    </div>
  </div>

  <div v-else>
    <div class="d-flex justify-content-center mt-2 mb-2">
      <ct-centered-spinner variant="primary" large />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'EmailServiceInfo',
  components: {
    ClientSetup:       () => import('@/components/Services/BusinessEmail/ClientSetup'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    Settings:          () => import('@/components/Services/BusinessEmail/Settings'),
    SecondaryButton:   () => import('@/components/StagelineV2/shared/SecondaryButton'),
    PrimaryButton:     () => import('@/components/StagelineV2/shared/PrimaryButton'),
  },
  mixins: [makeToastMixin],

  data() {
    return {
      loaded: false,
      sendingTestEmail: false,
    }
  },
  computed: {
    ...mapGetters('domainEmails', [
      'domainEmail',
    ]),
    emailAddress() {
      return this.domainEmail?.email_address || ''
    },
    domainName() {
      return this.emailAddress.split("@")[1] || ''
    },
  },
  async mounted() {
    this.loaded = true
  },
  methods: {
    ...mapActions('domainEmails', [
      'sendTestEmail',
    ]),
    async sendTestDomainEmail() {
      this.sendingTestEmail = true
      const result = await this.sendTestEmail(this.domainEmail.id)
      if (result.success) {
        this.successToast('Success', 'Test email has been sent successfully.')
      } else {
        this.errorToast('Error', 'Unable to send test email.')
      }
      this.sendingTestEmail = false
    },
  },
}
</script>
<style scoped lang="scss">
.button-container {
  float: right;
}
</style>
