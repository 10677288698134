var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "email-service-info-slide-container" },
        [
          _c("client-setup"),
          _c("settings", { attrs: { domain: _vm.domainName } }),
          _c(
            "div",
            { staticClass: "mt-3 mb-4 button-container" },
            [
              _c("secondary-button", {
                staticClass: "mr-2",
                attrs: {
                  "button-text": "Test Email",
                  loading: _vm.sendingTestEmail,
                  "aria-label": "test email button",
                },
                on: {
                  onclick: function ($event) {
                    return _vm.sendTestDomainEmail()
                  },
                },
              }),
              _c("primary-button", {
                attrs: {
                  "button-text": "Continue",
                  "aria-label": "continue button",
                },
                on: {
                  onclick: function ($event) {
                    return _vm.$emit("next-slide")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _c("div", [
        _c(
          "div",
          { staticClass: "d-flex justify-content-center mt-2 mb-2" },
          [
            _c("ct-centered-spinner", {
              attrs: { variant: "primary", large: "" },
            }),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }